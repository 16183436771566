.main-content {
    padding: 2em;
}
.section-card {
    width: 100%;
}

.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: white;
}

.header div {
    display: inline-block;
}

.header .logo img {
    width: 12em;
}

.header .logged-in-user {
    text-align: end;
}